:root {
  --zIndex-1: 1;
  --zIndex-2: 2;
  --zIndex-3: 3;
  --zIndex-4: 4;

  // map vars
  --zIndex-baseMap: 400;
  --zIndex-gpsWrapper: 500;
  --zIndex-mapLoading: 9999;
}

body {
  --color-background: #fafafa;
  --color-foreground: #1f2023;
  --color-links: rgb(16, 60, 119);
  --color-reactTable-highlight: #d9d9d9;
  --color-reactTable-pagination: #d9d9d9;
  --color-reactTable-border: 1px solid rgba(0, 0, 0, 0.02);
  --color-reactTable-striped: rgba(0, 0, 0, 0.03);
  --color-reactTable-base: #fafafa;
  --color-reactTable-sort-desc: inset 0 -3px 0 0 rgb(0 0 0 / 60%);
  --color-reactTable-sort-asc: inset 0 3px 0 0 rgb(0 0 0 / 60%);
  --color-flagged: #59ee8a;
  --color-flagging: #d7da43;
  --color-intel: #76cbe6;
  --color-reactTable-pagination-bg: #fafafa;
  --color-reactTable-pagination-fg: black;
  --color-reactTable-pagination-button-fg: black;
  --color-reactTable-pagination-button-bg: silver;
  --color-reactable-paginfo-fg: black;
  --image-width: 235px;

  &.dark {
    --color-background: #1f2023;
    --color-foreground: #efefef;
    --color-links: #58a4f0;
    --color-reactTable-highlight: #5c3e3ec5;
    --color-reactTable-border: 1px solid #757171;
    --color-reactTable-striped: #312c2c;
    --color-reactTable-base: #c0c0c0;
    --color-reactTable-sort-desc: inset 0 -3px 0 0 rgba(255, 255, 255, 0.839);
    --color-reactTable-sort-asc: inset 0 3px 0 0 rgb(255, 255, 255, 0.839);
    --color-flagged: #3ec46b;
    --color-flagging: #b3b537;
    --color-intel: #6ab7cf;
    --color-reactTable-pagination-bg: #241e1e;
    --color-reactTable-pagination-fg: #efefef;
    filter: brightness(80%);
    background: #1f2023;
    color: #efefef;
    --color-reactTable-pagination-button-fg: #efefef;
    --color-reactTable-pagination-button-bg: #424040;
    --color-reactable-paginfo-fg: #efefef;

    .linkWithColoredBg {
      font-weight: bold;
      color: rgb(14, 13, 27);
    }
  }

  // for mobile device preference
  @media (prefers-color-scheme: dark) {
    --color-background: #1f2023;
    --color-foreground: #efefef;
    --color-links: rgb(52, 112, 172);
    --color-reactTable-highlight: #f4f4f4c5;
    --color-reactTable-border: 1px solid #fafafa;
    --color-reactTable-striped: rgb(120, 120, 120);
    --color-reactTable-base: #c0c0c0;
    --color-reactTable-sort-desc: inset 0 -3px 0 0 rgba(255, 255, 255, 0.839);
    --color-reactTable-sort-asc: inset 0 3px 0 0 rgb(255, 255, 255, 0.839);
    filter: brightness(80%);
    background: #1f2023;
    color: #efefef;
    --color-reactTable-pagination-button-fg: #efefef;
    --color-reactTable-pagination-button-bg: #424040;
    --color-reactable-paginfo-fg: #efefef;
  }
}
